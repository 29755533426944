<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-light bg-light sticky-top">
      <div class="container">
        <router-link to="/" title="Website Title" class="navbar-brand">
          <img src="./assets/logo-light.jpg" border="0" alt="Null Bounce" style="max-width: 150px" />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#topnavbar"
          aria-controls="topnavbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div id="topnavbar" class="collapse navbar-collapse">
          <div style="flex-grow: 1"></div>
          <ul id="menu-main-menu" class="navbar-nav" @click="hideMenu($event)">
            <li class="nav-item">
              <router-link :to="{ name: 'Features' }" class="nav-link">Features</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'Price' }" class="nav-link">Pricing</router-link>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" href="#">API Validation</a>
            </li> -->
            <li class="nav-item">
              <a class="nav-link" href="/docs">Documentation</a>
            </li>
          </ul>
          <div style="flex-grow: 1"></div>
          <div>
            <a
              class="ps-0 me-2 btn btn-sm btn1-outline-secondary"
              :href="`${appServer}/login`"
            >LOG IN</a>
          </div>
          <div>
            <a class="btn btn-sm btn-primary fw-bold" :href="`${appServer}/register`">SIGN UP FREE</a>
          </div>
        </div>
      </div>
    </nav>
    <router-view />
    <nav class="navbar navbar-expand-md navbar-light bg-light pt-5">
      <div class="container align-items-start justify-content-around">
        <div>
          <div class="fw-bold">COMPANY</div>
          <div class="nav-item">            
            <a class="nav-link text-secondary ps-0" href="mailto:support@nullbounce.com" target="_blank">support@nullbounce.com</a>
          </div>
          <div class="nav-item">
            <a class="nav-link text-secondary ps-0" :href="`${docServer}/docs/legal/privacy-policy.html`" target="_blank">Privacy Policy</a>
          </div>
          <div class="nav-item">
            <a class="nav-link text-secondary ps-0" :href="`${docServer}/docs/legal/terms-of-use.html`" target="_blank">Terms of Use</a>
          </div>
          <div class="nav-item">
            <a class="nav-link text-secondary ps-0" :href="`${docServer}/docs/legal/gdpr.html`" target="_blank">GDPR Compliancy</a>
          </div>
          <!-- <div class="nav-item">
            <a class="nav-link text-secondary ps-0" href="#">FAQ</a>
          </div> -->
          <!-- <div class="nav-item">
            <a class="nav-link text-secondary ps-0" href="#">Site Map</a>
          </div> -->
        </div>
        <div>
          <div class="fw-bold">PRODUCT</div>
          <div class="nav-item">
            <router-link :to="{ name: 'Price' }" class="nav-link text-secondary ps-0">Pricing</router-link>
          </div>
          <div class="nav-item">
            <router-link :to="{ name: 'Features' }" class="nav-link text-secondary ps-0">Features</router-link>
          </div>
          <!-- <div class="nav-item">
            <a class="nav-link text-secondary ps-0" href="#">FAQ</a>
          </div> -->
          <div class="nav-item">
            <a class="nav-link text-secondary ps-0" :href="`${docServer}/docs`" target="_blank">Documentation</a>
          </div>
          <!-- <div class="nav-item">
            <a class="nav-link text-secondary ps-0" href="#">Site Map</a>
          </div> -->
        </div>
        <div class="text-center">
          <div class="fw-bold text-start1">TRUSTED SECURITY</div>
          <div class="text-secondary my-3">We will never share your data</div>
          <!-- <img src="./assets/gdpr.png" border="0" alt="Null Bounce" style="max-width: 100px" /> -->
          <img src="./assets/gdpr.svg" border="0" alt="Null Bounce" style="width: 100%" />
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  computed: {
    appServer() {
      return process.env.VUE_APP_APP_SERVER
    },
    docServer() {
      return process.env.VUE_APP_DOC_SERVER
    }
  },
  methods: {
    hideMenu() {
      document.querySelector('#topnavbar').classList.remove('show')
      document.querySelector('.navbar-toggler').classList.add('collapsed')
    }
  }
}
</script>


<style>
</style>
