<template>
    <div>
        <div class="my-4">
            <div
                v-for="(item, index) in prices"
                :key="item.cost"
                class="d-flex flex-wrap align-items-center py-3 mx-auto border-bottom border-2"
                style="max-width: 720px"
            >
                <div
                    class="px-5 py-2 fw-bold text-primary"
                    style="min-width: 250px"
                >{{ item.amount.toLocaleString() }} emails</div>
                <div
                    class="px-5 py-2 fw-bold"
                    style="min-width: 160px"
                >${{ item.cost.toLocaleString() }}</div>
                <div
                    class="px-5"
                >${{ (item.cost / item.amount).toLocaleString([], { maximumFractionDigits: 4, minimumFractionDigits: 4 }) }} / email</div>
                <div v-if="index > 0" class="ms-5 m-md-0 text-success fw-bold">
                    Save
                    {{
                        (
                            (prices[0].cost / prices[0].amount - item.cost / item.amount) /
                            (prices[0].cost / prices[0].amount)
                            * 100
                        )
                            .toLocaleString([], { maximumFractionDigits: 10 })
                    }}%!
                </div>
            </div>
        </div>
        <div class="text-center fst-italic">The prices include all taxes and fees.</div>
        <div class="text-center">No monthly payment, no upfront fee, credits never expire.</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            prices: [
                { amount: 1000, cost: 5 },
                { amount: 5000, cost: 10 },
                { amount: 10000, cost: 15 },
                { amount: 25000, cost: 30 },
                { amount: 50000, cost: 50 },
                { amount: 100000, cost: 90 },
                { amount: 200000, cost: 140 },
                { amount: 500000, cost: 300 },
                { amount: 1000000, cost: 500 },
                { amount: 2000000, cost: 800 },
                { amount: 5000000, cost: 1500 },
            ],
        }
    }
}
</script>