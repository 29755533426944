<template>
    <div class="container my-5">
        <h1 class="text-center fw-bold">Email Verification Pricing</h1>
        <div>
            <!-- <div class="text-center">Calculator</div> -->
            <PriceTable />
            <div class="text-center my-5">
                <a
                    :href="`${appServer}/register`"
                    class="btn btn-primary rounded-pill btn-lg fw-bold px-5 py-3"
                >Sign up now and get 100 FREE credits</a>
            </div>
        </div>
        <div class="my-4 py-4">
            <h2 class="text-center">Included with all Null Bounce Accounts</h2>
            <div class="row m-3 m-md-5">
                <div v-for="feature in features" :key="feature.header" class="col-md-4 my-3">
                    <div class11="mx-auto1 mx-md-01" style11="width: fit-content">
                        <h4 class="text-primary fw-bold fs-5">{{ feature.header }}</h4>
                        <div>{{ feature.text }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mx-auto" style="max-width: 700px; text-align: justify;">
            <h2 class="text-center">Frequently Asked Questions</h2>
            <div>
                <div class="my-4">
                    <h4 class="fw-bold text-muted">Do my credits ever expire?</h4>
                    <div>
                        No.
                        All the credits you purchase or receive for free will always be available in your account.
                    </div>
                </div>
                <div class="my-4">
                    <h4 class="fw-bold text-muted">How fast is Null Bounce?</h4>
                    <div>
                        It depends on factors such as list size and quality.
                        We use the best tools and practices in the industry to guarantee quick list processing.
                        An average 100,000 email job is completed in less than 2 hours.
                    </div>
                </div>
                <div class="my-4">
                    <h4 class="fw-bold text-muted">Do you share my data?</h4>
                    <div>
                        No, never.
                        All your data are erased 30 days after you upload your file unless you choose to delete it before that.
                    </div>
                </div>
                <div class="my-4">
                    <h4 class="fw-bold text-muted">What payment methods do you support?</h4>
                    <div>
                        <div>
                            We support all major payment cards such as MasterCard, Visa, and Maestro.
                            We also support PayPal and American Express.
                        </div>
                        <div class="my-2">
                            <img
                                src="../../../frontend/src/assets/payments.png"
                                height="30"
                                alt="payment methods"
                            />
                        </div>
                        <div>
                            Please click
                            <a
                                href="https://paddle.com/support/which-payment-methods-do-you-support/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >here</a>
                            to see the full list of payment methods.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PriceTable from '@/components/PriceTable.vue'

export default {
    components: {
        PriceTable
    },
    data() {
        return {
            // prices: [
            //     { amount: 1000, cost: 5 },
            //     { amount: 5000, cost: 10 },
            //     { amount: 10000, cost: 15 },
            //     { amount: 25000, cost: 30 },
            //     { amount: 50000, cost: 50 },
            // ],
            features: [
                {
                    'header': 'Pay as You Go',
                    'text': 'You may purchase an arbitrary credits number'
                },
                {
                    'header': 'Volume Discounts',
                    'text': 'The more emails you verify, the less you pay per email.'
                },
                {
                    'header': 'Deduplication for Free',
                    'text': 'You never pay for duplicates and we preserve all input rows for easy use the output file'
                },
                {
                    'header': 'Unknown Results are Free',
                    'text': "If we can't verify an email, you won’t pay for it"
                },
                {
                    'header': 'No Hidden Costs',
                    'text': 'All features are included within your plan.'
                },
                {
                    'header': 'Multiple API Keys included',
                    'text': 'No need to buy separate credits to use API.'
                },
            ]
        }
    },
    computed: {
        appServer() {
            return process.env.VUE_APP_APP_SERVER
        }
    }
}
</script>