<template>
  <div class="container">
    <h1 class="text-center my-5 text-primary fw-bold">
      Smart Email Verification
      <br />at Reasonable Prices
    </h1>
    <div class="row my-5 pt-4">
      <div class="col-md-4 mb-5">
        <div class="my-3">
          <div class="fw-bold fs-5">
            <span class="text-primary me-1">1.</span>Register a FREE account
          </div>
          <div>Get 100 email verifications for FREE.</div>
        </div>
        <div class="my-3">
          <div class="fw-bold fs-5">
            <span class="text-primary me-1">2.</span>Upload your email list
          </div>
          <div>We accept CSV and TXT file formats.</div>
        </div>
        <div class="my-3">
          <div class="fw-bold fs-5">
            <span class="text-primary me-1">3.</span>Download a cleaned list
          </div>
          <div>You will be notified by email.</div>
        </div>
        <div class="d-grid mt-4">
          <a
            :href="`${appServer}/register`"
            class="btn btn-primary btn-lg fw-bold 1fst-italic px-5 py-3"
          >Get 100 free verifications</a>
        </div>
      </div>
      <div class="col ms-3">
        <!-- <img src="../assets/interface.jpg" style="width: 100%;" /> -->
        <img src="../assets/interface.png" style="width: 100%;" />
      </div>
    </div>

    <!-- <div>How It Works??</div> -->
    <!-- Why need email validation? -->
    <Validator />
    
    <Features />
    
    <h2 class="text-center fw-bold">Email Verification Pricing</h2>
    <PriceTable />
    
    <div class="my-5 py-4">
      <h2 class="text-center fw-bold">Get Started Now</h2>
      <div class="text-center">Sign up and get 100 free credits.</div>
      <div class="text-center my-3">
        <a
          :href="`${appServer}/register`"
          class="btn btn-primary rounded-pill btn-lg fw-bold px-3 py-2"
        >TRY FOR FREE</a>
      </div>
    </div>
  </div>
</template>

<script>
import Validator from '@/components/Validator.vue'
import Features from '@/components/Features.vue'
import PriceTable from '@/components/PriceTable.vue'

export default {
  name: "Home",
  components: {
    Validator,
    Features,
    PriceTable
  },
  computed: {
    appServer() {
      return process.env.VUE_APP_APP_SERVER
    }
  }
};
</script>
