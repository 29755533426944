<template>
    <div>
        <h2 class="text-center fw-bold">How we validate emails</h2>
        <div class="row my-5">
            <div class="col-md-4 my-4 px-3" v-for="adv in advs" :key="adv.header">
                <h3 class="fs-5 text-primary fst-italic">{{ adv.header }}</h3>
                <div>{{ adv.text }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            advs: [
                { header: 'Syntax errors validator', text: 'Syntax error checker removes emails with invalid syntax.' },
                { header: 'Disposable emails cleaner', text: 'Get rid of short-lived email addresses. We determine temporary emails: your future emails will reach an inbox.' },
                { header: 'Catch-All Domain checker', text: 'Null Bounce email verifier identifies catch-all domains that return valid status for all emails.' },
                { header: 'Email duplicates checker', text: 'We detect all duplicate emails in your list and you pay only once for each.' },
                { header: 'Role and free email', text: 'We  show which addresses are role-based and which are served by free email providers.' },
                { header: 'Anti-greylisting method', text: 'Our engine behaves in accordance with standards for email servers to reduce unknown email results.' },
                { header: 'Hard bounce validator', text: 'Our email engine tells you which addresses will bounce. Validate your list regularly to have a low bounce rate.' },
                { header: 'MTA Validation', text: 'Is there a server accepting email messages? We make elaborate checks to validate MX records.' },
                { header: 'Unknown results are free', text: 'You pay only for successfully validated emails. All unknowns are free.' }
            ]
        }
    },
}
</script>