<template>
    <div class="my-5 py-5">
        <h2 class="text-center fw-bold">Test Our Service Right Now!</h2>
        <div class="mx-auto mt-4" style="max-width: 350px">
            <div class="my-3">Type an email address and we try to validate it</div>
            <form class="d-flex align-items-end" @submit.prevent="validate">
                <div class="me-3">
                    <input
                        @input="error = ''; result = null"
                        v-model.trim="email"
                        id="emailInput"
                        type="email"
                        required
                        class="form-control"
                        placeholder="email@example.com"
                    />
                </div>
                <div>
                    <button type="submit" :disabled="loading" class="btn btn-success">Validate</button>
                </div>
            </form>
        </div>
        <div v-if="loading" class="text-center mt-3">
            Verifying...
            <div class="spinner-border text-info me-3"></div>
        </div>
        <div v-if="result" class="my-4 mx-auto" style="max-width: 500px">
            <div class="my-3">
                <BIconCheckCircleFill
                    v-if="result.result == 'Safe'"
                    class="text-success me-2"
                    width="40px"
                    height="40px"
                />
                <BIconShieldFillX
                    v-if="result.result == 'Invalid'"
                    class="text-danger me-2"
                    width="40px"
                    height="40px"
                />
                <BIconShieldFillX
                    v-if="result.result == 'Risky'"
                    class="text-warning me-2"
                    width="40px"
                    height="40px"
                />
                <BIconQuestionCircleFill
                    v-if="result.result == 'Unknown'"
                    class="text-secondary me-2"
                    width="40px"
                    height="40px"
                />
                The email is {{ result.result }}
            </div>
            <div class="row">
                <div class="col-6 my-1">
                    <span class="text-primary me-2">Deliverable:</span>
                    <span
                        class="me-2"
                        :class="{
                            'text-danger': result.status_text === 'Invalid' ||
                                result.status_text === 'Unknown',
                            'text-success': result.status_text === 'Valid'
                        }"
                    >{{ statusText }}</span>
                    <div v-if="result.substatus_text">({{ result.substatus_text.toLowerCase() }})</div>
                </div>
                <div class="col-6 my-1">
                    <span class="text-primary me-2">Disposable:</span>
                    <span
                        :class="{ 'text-danger': result.is_disposable, 'text-success': !result.is_disposable }"
                    >{{ result.is_disposable }}</span>
                </div>
                <div class="col-6 my-1">
                    <span class="text-primary me-2">Role:</span>
                    <span
                        :class="{ 'text-warning fw-bold': result.is_role, 'text-success': !result.is_role }"
                    >{{ result.is_role }}</span>
                </div>
                <div class="col-6 my-1">
                    <span class="text-primary me-2">Free Email:</span>
                    <span>{{ result.is_free }}</span>
                </div>
            </div>
            <div class="my-3">
                <a
                    :href="`${appServer}/register`"
                    class="btn btn-outline-primary btn-sm m-2"
                >Register now</a> and get acccess to all features and 100 free credits.
            </div>
        </div>
        <div class="alert alert-warning my-4 mx-auto" v-if="error" style="max-width: 500px">
            Today's free common quota is over. Please,
            <a :href="`${appServer}/register`">register</a> your account to get 100 free credits for testing.
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { BIconCheckCircleFill, BIconShieldFillX, BIconQuestionCircleFill } from 'bootstrap-icons-vue'

export default {
    components: {
        BIconCheckCircleFill,
        BIconShieldFillX,
        BIconQuestionCircleFill
    },
    data() {
        return {
            email: null,
            loading: false,
            result: null,
            error: null
        }
    },
    computed: {
        appServer() {
            return process.env.VUE_APP_APP_SERVER
        },
        statusText() {
            if (!this.result.status_text) {
                return ''
            }
            if (this.result.status_text == 'Valid') {
                return 'true'
            }
            if (this.result.status_text == 'Invalid') {
                return 'false'
            }
            return this.result.status_text.toLowerCase()
        }
    },
    methods: {
        validate() {
            this.loading = true
            this.error = null
            this.result = null
            axios.post(process.env.VUE_APP_API_URL + "/validate_demo/", {
                email: this.email
            })
                .then(resp => {
                    this.result = resp.data
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                    // this.error = err.response?.data?.detail
                    this.error = true
                })
        }
    }
}
</script>