import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/js/dist/collapse"
import VueYandexMetrika from 'vue3-yandex-metrika'
import VueGtag from "vue-gtag"

const app = createApp(App)
app.use(router)
app.use(VueYandexMetrika, {
	id: '87008046',
	router: router,
	env: process.env.NODE_ENV,
    options: {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true,
    },
    // debug: true,
})
if (!navigator.userAgent.includes('Prerender')) {
    app.use(VueGtag, {
        config: { id: "G-FWWV45H6EK" }    
    }, router);
}
app.mount("#app");
