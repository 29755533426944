<template>
    <div class="container my-5">
        <h2 class="text-center fw-bold">Access from Dashboard and API</h2>
        <div class="row my-5">
            <div class="col-md">
                <div
                    class="my-3"
                >From your dashboard, you can both verify a single email address and easily upload and clean your lists.</div>
                <!-- <img src="../assets/interface.jpg" style="width: 100%" alt /> -->
                <img src="../assets/interface.png" style="width: 100%" alt />
            </div>
            <div class="col-md">
                <div class="my-3">Also, you can make verification by means of a restfull API.</div>
                <br />
                <img src="../assets/keys.png" style="width: 100%" alt />
            </div>
        </div>
        <div>
            <h2 class="text-center fw-bold mb-3">Team Account</h2>
            <div>After registration, you can invite additional team members. All team members share a common credit balance.</div>
            <div>You can set different roles for your teammates and revoke access to the account at any time.</div>
            <div>We don't restrict the number of teammates in a single account: you can invite as many people as you need.</div>

            <div class="my-4">
                <img src="../assets/team.png" style="width: 100%" alt />
            </div>
        </div>
        <Features class="my-5" />
        <div class="text-center my-5">
            <a
                :href="`${appServer}/register`"
                class="btn btn-primary rounded-pill btn-lg fw-bold px-5 py-3"
            >Sign up now and get 100 FREE credits</a>
        </div>
    </div>
</template>

<script>
// import Validator from '@/components/Validator.vue'
import Features from '@/components/Features.vue'

export default {
    components: {
        Features
    },
    computed: {
        appServer() {
            return process.env.VUE_APP_APP_SERVER
        }
    }
};
</script>